import { SubLayoutProps } from "./types";

const LandingSectionLayout = ({
  children,
  allowOverflow = false,
  className,
}: SubLayoutProps): JSX.Element => (
  <div
    className={`relative md:px-6 px-5 lg:px-11 xl:px-20 h-full ${className} w-full ${
      allowOverflow ? "overflow-visible" : "overflow-hidden"
    }`}
  >
    {children}
  </div>
);

export default LandingSectionLayout;
